import React from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { Button, Col, Container, Table } from "reactstrap";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { useEffect } from "react";
import DeleteModal from "../../../utils/helpers/Modals/DeleteModal";
import {
  GetUnitDetails,
  deleteFinaltests,
  getFinaltest,
} from "../../../store/slices/courses";
import ShowSkeleton from "../../../utils/helpers/Skeleton";
import { Logout } from "../../../utils/helpers/Logout";
import ReactPaginate from "react-paginate";

const FinalTests = () => {
  const { id } = useParams();
  const [data, setData] = useState();
  const [showModal, setShowModal] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [totaldata, setTotaldata] = useState();
  const navigate = useNavigate();
  const [search, setSearch] = useSearchParams();
  const querypage = parseInt(search.get("page")) || 1;
  const [currentPage, setCurrentPage] = useState(querypage);
  const [breadData, setBreadData] = useState();

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    Logout();
    fetchData1();
  }, []);

  const fetchData1 = () => {
    dispatch(GetUnitDetails(id))
      .unwrap()
      .then((data) => {
        setBreadData(data?.user?.data[0]);
      })
      .catch(({ message }) => {});
  };

  console.log(breadData);
  const handleEditClick = () => {
    navigate(`/edit-finaltest/${id}`);
  };
  const handleAddFinaltest = () => {
    navigate(`/add-finaltest/${id}`);
  };
  const handleAddMoreSeriesClick = () => {
    navigate(`/addmoreseriestest/${id}`);
  };

  const itemsPerPage = 10;
  const pagesToShowInitially = 3;

  const handlePageChange = ({ selected }) => {
    const newPage = selected + 1 || currentPage;
    setCurrentPage(newPage);
    search.set("page", newPage);
    setSearch(search, { replace: true });
  };

  useEffect(() => {
    GetFinaltests();
  }, [itemsPerPage, currentPage]);

  const GetFinaltests = () => {
    setLoading(true);
    dispatch(getFinaltest(id))
      .unwrap()
      .then((data) => {
        setData(data.finaltest.data);

        setTotaldata(data?.finaltest?.totalDataCount);
        setDeleteId(data?.finaltest?.data[0]?._id);
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      })
      .catch((err) => {});
  };

  const handleDeleteClick = () => {
    setShowModal(true);
  };

  const handleDelete = () => {
    dispatch(deleteFinaltests(deleteId))
      .unwrap()
      .then((data) => {
        alert(data.finaltest.message);
        GetFinaltests();
        setShowModal(false);
      })
      .catch((errr) => {});
  };

  const courseid = localStorage.getItem("courseId");
  const categoryid = localStorage.getItem("categoryId");
  const combinationId = localStorage.getItem("combinationId");
  const combinationid1 = localStorage.getItem("combinationId1");
  const unitId = localStorage.getItem("unitId");
  const chapterId = localStorage.getItem("chapterId");
  return (
    <>
      <DeleteModal
        showModal={showModal}
        setShowModal={setShowModal}
        handleDelete={handleDelete}
      />
      <div>
        <React.Fragment>
          <div className="page-content">
            <Container fluid={true}>
              <div
                class="text-start my-2 ms-1"
                style={{
                  fontWeight: "600",
                }}
              >
                <Link to="/dashboard">Home</Link>&nbsp;&#8811;&nbsp;
                <Link to="/courses">Courses</Link>
                &nbsp;&#8811;&nbsp;
                <Link to={`/combination/${combinationId}`}>Combinations</Link>
                &nbsp;&#8811;&nbsp;
                <Link to={`/viewchapters/${combinationId}/${chapterId}`}>
                  {" "}
                  Chapters
                </Link>
                &nbsp;&#8811;&nbsp;
                <Link to={`/units/${unitId}`}>Units</Link>
                &nbsp;&#8811;&nbsp;Final Tests
              </div>
              <div class="card">
                <div class="card-header">
                  <div class="card-title-wrap bar-success d-flex justify-content-between align-items-center">
                    <div class="d-flex align-items-center">
                      <h4 class="card-title">
                        {breadData?.courseId.courseName} &#8811;{" "}
                        {breadData?.subjectId.subjectName} &#8811;{" "}
                        {breadData?.chapterId.chapterName} &#8811;{" "}
                        {breadData?.unitName}
                      </h4>
                      {!data?.length ? (
                        <button
                          className="btn btn-primary btn-sm ms-1"
                          onClick={handleAddFinaltest}
                        >
                          Add Questions
                        </button>
                      ) : (
                        <button
                          className="btn btn-primary btn-sm ms-2"
                          onClick={handleAddMoreSeriesClick}
                        >
                          Add More Questions
                        </button>
                      )}
                    </div>
                    {data?.length > 0 && (
                      <div className="td">
                        <button
                          className="btn btn-outline-info btn-sm"
                          onClick={handleEditClick}
                        >
                          Edit
                        </button>
                        <button
                          className="btn btn-outline-danger btn-sm ms-2"
                          onClick={handleDeleteClick}
                        >
                          Delete
                        </button>
                      </div>
                    )}
                  </div>
                </div>

                <div class="card-body collapse show">
                  <div class="card-block card-dashboard table-responsive">
                    {loading ? (
                      <Col lg={12} xs={12} md={12} align="center">
                        {ShowSkeleton()}
                      </Col>
                    ) : !data?.length ? (
                      <div className="mt-4 text-center card-body collapse show pb-3">
                        <h5> No questions were added to this unit</h5>
                      </div>
                    ) : (
                      <Table responsive hover style={{ overflowX: "scroll" }}>
                        <thead>
                          <tr>
                            <th className="text-center align-middle">
                              Question No
                            </th>
                            <th className="text-center align-middle">
                              Question
                            </th>
                            <th className="text-center align-middle">
                              Options
                            </th>
                            <th className="text-center align-middle">
                              Correct Option
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {data?.map((item, index) =>
                            item?.questions?.map((question, ind) => (
                              <tr key={ind}>
                                <td className="text-center align-middle">
                                  {question.questionNumber}
                                </td>
                                <td className="text-center align-left">
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: question.question.replace(
                                        '<p data-f-id="pbf" style="text-align: center; font-size: 14px; margin-top: 30px; opacity: 0.65; font-family: sans-serif;">Powered by <a href="https://www.froala.com/wysiwyg-editor?pb=1" title="Froala Editor">Froala Editor</a></p>',
                                        ""
                                      ),
                                    }}
                                  />
                                </td>
                                <td>
                                  {question.options.map((elem) => (
                                    <p className="text-center align-left">
                                      {elem.optionNumber}-&nbsp;
                                      {elem.optionValue}
                                    </p>
                                  ))}
                                </td>
                                <td className="text-center align-left">
                                  {question.correctOption}
                                </td>
                              </tr>
                            ))
                          )}
                        </tbody>
                      </Table>
                    )}
                  </div>

                  <div className="mr-5">
                    {totaldata / itemsPerPage > 1 && (
                      <div className="mt-5 d-flex justify-content-end align-right">
                        <ReactPaginate
                          key={currentPage}
                          previousLabel="<"
                          nextLabel=">"
                          breakLabel="..."
                          breakLinkClassName={"page-link"}
                          pageCount={Math.ceil(totaldata / itemsPerPage)}
                          marginPagesDisplayed={1}
                          pageRangeDisplayed={pagesToShowInitially}
                          onPageChange={handlePageChange}
                          containerClassName="pagination"
                          activeClassName="active"
                          pageLinkClassName="page-link"
                          previousLinkClassName="page-link"
                          nextLinkClassName="page-link"
                          disabledClassName="disabled"
                          initialPage={currentPage - 1}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </Container>
          </div>
        </React.Fragment>
      </div>
    </>
  );
};
export default FinalTests;
